import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    const isAuthenticated: boolean = this.authService.isAuthenticated();

    if (!isAuthenticated) {
      this.authService.removeAuthentication();
      this.authService.setRedirectUrl(url);
      this.router.navigateByUrl('/auth');
      return false;
    } else {
      return true;
    }
  }
}
